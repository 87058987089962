import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "h-full text-hub-text"
}
const _hoisted_2 = {
  key: 0,
  class: "md:grid grid-cols-8 h-full"
}
const _hoisted_3 = { class: "flex justify-between gap-4 items-end h-full py-2 pl-5 pr-8 bg-hub-background-3" }
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "font-normal" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 1 }

import { usePubHubs } from '@/core/pubhubsStore';
	import { LOGGER } from '@/foundation/Logger';
	import { SMI } from '@/dev/StatusMessage';
	import { useDialog } from '@/store/dialog';
	import { useMenu } from '@/store/menu';
	import { MessageType } from '@/store/messagebox';
	import { usePlugins } from '@/store/plugins';
	import { RoomType } from '@/store/rooms';
	import { FeatureFlag, HubInformation, useSettings } from '@/store/settings';
	import { Message, MessageBoxType, useHubSettings, useMessageBox, useRooms } from '@/store/store';
	import { useUser } from '@/store/user';
	import { getCurrentInstance, onMounted, ref, watch } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { RouteParamValue, useRouter } from 'vue-router';

	import Avatar from '@/components/ui/Avatar.vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

	const { locale, availableLocales } = useI18n();
	const router = useRouter();
	const settings = useSettings();
	const hubSettings = useHubSettings();
	const user = useUser();
	const rooms = useRooms();
	const messagebox = useMessageBox();
	const dialog = useDialog();
	const pubhubs = usePubHubs();
	const plugins = usePlugins();
	const menu = useMenu();
	const settingsDialog = ref(false);
	const setupReady = ref(false);
	const disclosureEnabled = settings.isFeatureEnabled(FeatureFlag.disclosure);

	watch(
		() => rooms.totalUnreadMessages,
		() => {
			rooms.sendUnreadMessageCounter();
		},
	);

	onMounted(() => {
		plugins.setPlugins(getCurrentInstance()?.appContext.config.globalProperties._plugins, router);
	});

	onMounted(async () => {
		LOGGER.trace(SMI.STARTUP_TRACE, 'App.vue onMounted');

		settings.initI18b({ locale: locale, availableLocales: availableLocales });
		// set language when changed
		settings.$subscribe(() => {
			locale.value = settings.getActiveLanguage;
		});

		// check if hash doesn't start with hub,
		// then it is running only the hub-client, so we need to do some checks
		if (!window.location.hash.startsWith('#/hub/')) {
			await pubhubs.login();
			setupReady.value = true; // needed if running only the hub-client
			router.push({ name: 'home' });
		}
		if (!user.isLoggedIn) {
			// only needed when loggedIn (then there are user settings to setup)
			setupReady.value = true;
		}
		await startMessageBox();

		LOGGER.trace(SMI.STARTUP_TRACE, 'App.vue onMounted done');
	});

	async function startMessageBox() {
		if (!hubSettings.isSolo) {
			await messagebox.init(MessageBoxType.Child, hubSettings.parentUrl);

			// Ask for Hub name etc.
			messagebox.addCallback(MessageType.HubInformation, (message: Message) => {
				settings.hub = message.content as HubInformation;
			});

			// Listen to roomchange
			messagebox.addCallback(MessageType.RoomChange, async (message: Message) => {
				const roomId = message.content as RouteParamValue;
				if (rooms.currentRoomId !== roomId) {
					rooms.currentRoomId = roomId;
					await rooms.getSecuredRoomInfo(roomId);
					if (rooms.securedRoom && rooms.securedRoom !== null) {
						router.push({ name: 'secure-room', params: { id: roomId } });
					} else {
						router.push({ name: 'room', params: { id: roomId } });
					}
				}
			});

			//Listen to global menu change
			messagebox.addCallback(MessageType.BarHide, () => {
				hubSettings.mobileHubMenu = false;
			});

			messagebox.addCallback(MessageType.BarShow, () => {
				hubSettings.mobileHubMenu = true;
			});

			// Wait for theme change happened
			// const wait = setInterval(() => {
			// 	console.log('Waiting...', messageBoxStarted);
			// 	if (messageBoxStarted) {
			// 		setupReady.value = true;
			// 		clearInterval(wait);
			// 	}
			// }, 250);
			// setTimeout(() => {
			// 	clearInterval(wait);
			// 	setupReady.value = true;
			// }, 2500);
		}
	}

return (_ctx: any,_cache: any) => {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_H2 = _resolveComponent("H2")!
  const _component_RoomList = _resolveComponent("RoomList")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DiscoverUsers = _resolveComponent("DiscoverUsers")!
  const _component_HeaderFooter = _resolveComponent("HeaderFooter")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Disclosure = _resolveComponent("Disclosure")!
  const _component_SettingsDialog = _resolveComponent("SettingsDialog")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_unref(settings).getActiveTheme, "h-full"])
  }, [
    (setupReady.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_unref(user).isLoggedIn)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_HeaderFooter, {
                  class: _normalizeClass(["md:col-span-2 md:flex bg-hub-background-2", { hidden: !_unref(hubSettings).mobileHubMenu }])
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      (_unref(hubSettings).isSolo && _unref(settings).isFeatureEnabled(_unref(FeatureFlag).notifications) && _unref(rooms).totalUnreadMessages > 0)
                        ? (_openBlock(), _createBlock(_component_Badge, {
                            key: 0,
                            class: "-ml-4 -mt-2 w-8 flex-none"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(rooms).totalUnreadMessages), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_Logo, {
                        class: "inline-block h-12",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/'))),
                        title: _unref(settings).hub.name
                      }, null, 8, ["title"]),
                      _createElementVNode("span", {
                        class: "mt-6 truncate",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push('/'))),
                        title: _unref(settings).hub.name
                      }, _toDisplayString(_unref(settings).hub.name), 9, _hoisted_4),
                      _createVNode(Avatar, {
                        user: _unref(user),
                        onClick: _cache[2] || (_cache[2] = ($event: any) => {
									settingsDialog.value = true;
									_unref(hubSettings).hideBar();
								}),
                        class: "cursor-pointer w-8 h-8 text-md"
                      }, null, 8, ["user"])
                    ])
                  ]),
                  footer: _withCtx(() => [
                    (_unref(disclosureEnabled) && _unref(user).isAdmin)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createVNode(_component_H2, { class: "pl-5 border-b mr-8" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('menu.moderation_tools')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_Menu, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_MenuItem, {
                                to: { name: 'ask-disclosure' },
                                icon: "sign"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('menu.moderation_tools_disclosure')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true),
                    (_unref(user).isAdmin)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createVNode(_component_H2, { class: "pl-5 border-b mr-8" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('menu.admin_tools')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_Menu, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_MenuItem, {
                                to: { name: 'admin' },
                                icon: "admin"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('menu.admin_tools_rooms')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_Menu, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menu).getMenu, (item, index) => {
                          return (_openBlock(), _createBlock(_component_MenuItem, {
                            key: index,
                            to: item.to,
                            icon: item.icon,
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(hubSettings).hideBar()))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t(item.key)), 1)
                            ]),
                            _: 2
                          }, 1032, ["to", "icon"]))
                        }), 128))
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_H2, { class: "pl-5 border-b mr-8" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('menu.rooms')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_RoomList),
                    _createVNode(_component_Button, {
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(router).push('/discoverRooms'))),
                      class: "mx-auto py-1 my-2 w-5/6",
                      color: 'gray'
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Icon, {
                          type: "compass",
                          class: "absolute left-0 top-0 -ml-1 -mt-2"
                        }),
                        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('rooms.discover')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_H2, { class: "pl-5 border-b mr-8" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('menu.private_rooms')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_RoomList, {
                      roomType: _unref(RoomType).PH_MESSAGES_DM
                    }, null, 8, ["roomType"]),
                    _createVNode(_component_DiscoverUsers)
                  ]),
                  _: 1
                }, 8, ["class"]),
                _createElementVNode("div", {
                  class: _normalizeClass(["md:col-span-6 md:block dark:bg-gray-middle h-full overflow-y-auto scrollbar", { hidden: _unref(hubSettings).mobileHubMenu }])
                }, [
                  _createVNode(_component_router_view)
                ], 2)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_router_view)
              ]))
        ]))
      : _createCommentVNode("", true),
    (_unref(disclosureEnabled))
      ? (_openBlock(), _createBlock(_component_Disclosure, { key: 1 }))
      : _createCommentVNode("", true),
    (settingsDialog.value)
      ? (_openBlock(), _createBlock(_component_SettingsDialog, {
          key: 2,
          onClose: _cache[5] || (_cache[5] = ($event: any) => (settingsDialog.value = false))
        }))
      : _createCommentVNode("", true),
    (_unref(dialog).visible)
      ? (_openBlock(), _createBlock(_component_Dialog, {
          key: 3,
          onClose: _unref(dialog).close
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})