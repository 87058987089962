import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex gap-x-1"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

import { useRooms } from '@/store/store';
	import { ref, watch } from 'vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileAttributes',
  props: {
		user: {
			type: String,
			required: true,
		},
		room_id: {
			type: String,
			required: true,
		},
	},
  setup(__props) {

	const rooms = useRooms();

	const props = __props;

	const roomAttributes: Ref<String[]> = ref([]);

	function update_attributes() {
		if (rooms.roomNotices[props.room_id] && rooms.roomNotices[props.room_id][props.user]) {
			roomAttributes.value = rooms.roomNotices[props.room_id][props.user];
		}
	}

	update_attributes();

	watch(
		() => rooms.roomNotices[props.room_id],
		() => {
			update_attributes();
		},
		{ deep: true },
	);

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (roomAttributes.value.length > 0)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(roomAttributes.value, (value) => {
          return (_openBlock(), _createElementBlock("span", {
            key: value,
            class: _normalizeClass([value === 'rooms.admin_badge' ? 'bg-red' : 'bg-black', "text-white text-xs lowercase px-1 rounded h-4 flex gap-1 items-center"])
          }, [
            _createVNode(_component_Icon, {
              type: "check",
              class: "mt-3 -mr-3"
            }),
            (value === 'rooms.admin_badge')
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t(value)), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(value), 1))
          ], 2))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})