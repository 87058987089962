import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center md:items-start md:flex-row mb-4" }
const _hoisted_2 = { class: "md:w-2/6 font-semibold text-gray" }
const _hoisted_3 = { class: "md:w-4/6 flex flex-col md:flex-row justify-between" }
const _hoisted_4 = { class: "flex justify-center md:justify-normal md:flex-col md:space-y-4 mt-5 md:mr-3" }
const _hoisted_5 = { for: "avatar" }
const _hoisted_6 = { class: "flex flex-col md:flex-row mb-4" }
const _hoisted_7 = { class: "w-2/6 font-semibold text-gray" }
const _hoisted_8 = { class: "flex flex-col md:flex-row mb-4" }
const _hoisted_9 = { class: "w-2/6 font-semibold text-gray" }
const _hoisted_10 = {
  title: "Hub specific User ID",
  class: "md:w-4/6 p-1 text-gray-light italic text-lg"
}
const _hoisted_11 = {
  key: 0,
  class: "rounded-lg bg-green-dark text-white p-2 mt-2"
}

import { fileUpload } from '@/composables/fileUpload';
	import { FormDataType, useFormState } from '@/composables/useFormState';
	import { useMatrixFiles } from '@/composables/useMatrixFiles';
	import { usePubHubs } from '@/core/pubhubsStore';
	import { useSettings } from '@/store/settings';
	import { buttonsSubmitCancel, DialogButtonAction, DialogSubmit, useDialog } from '@/store/store';
	import { useUser } from '@/store/user';
	import { onMounted, ref, watch } from 'vue';
	import { useI18n } from 'vue-i18n';

	// Components
	import Dialog from '../ui/Dialog.vue';
	import Avatar from '../ui/Avatar.vue';
	import Icon from '../elements/Icon.vue';
	import TextInput from './TextInput.vue';
	import ValidationErrors from './ValidationErrors.vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'SettingsDialog',
  setup(__props) {

	const { t } = useI18n();
	const user = useUser();
	const settings = useSettings();
	const dialog = useDialog();
	const formState = useFormState();
	const pubhubs = usePubHubs();
	const { imageTypes, uploadUrl } = useMatrixFiles();

	let avatarMxcUrl = ref<string | undefined>(undefined);
	let avatarUrl = ref<string | undefined>(undefined);

	watch(avatarMxcUrl, updateAvatarUrl);

	formState.setData({
		displayName: {
			value: user.user.displayName as string,
			validation: { required: true, max_length: settings.getDisplayNameMaxLength, allow_empty_number: false, allow_empty_object: false, allow_empty_text: true },
			show_validation: { required: false, max_length: true },
		},
	});

	onMounted(() => {
		formState.setSubmitButton(getSubmitButton());
	});

	onMounted(() => {
		formState.data.displayName.value = user.user.displayName as FormDataType;
	});

	function dialogAction(action: DialogButtonAction) {
		if (action === DialogSubmit) {
			submit();
		}
	}

	function getSubmitButton() {
		return dialog.properties.buttons[0];
	}

	async function submit() {
		// This check enables empty values to be submitted since dataIsChanged() method can't handle empty values conditional cal.
		if (formState.dataIsChanged('displayName')) {
			const newDisplayName = formState.data.displayName.value as string;
			await pubhubs.changeDisplayName(newDisplayName);
			formState.setMessage(t('settings.displayname_changed', [newDisplayName]));
			formState.updateData('displayName', newDisplayName);
		}

		if (avatarMxcUrl.value !== undefined) {
			user.setAvatarMxcUrl(avatarMxcUrl.value, true);
		}
	}

	async function updateAvatarUrl(): Promise<void> {
		if (!avatarMxcUrl.value) {
			avatarUrl.value = avatarMxcUrl.value;
			return;
		}

		const url = await pubhubs.getAuthorizedMediaUrl(avatarMxcUrl.value);
		if (url === null) throw new Error('Could not get authorized media URL');

		avatarUrl.value = url;
		return;
	}

	// Avatar related functions
	async function uploadAvatar(event: Event) {
		const accessToken = pubhubs.Auth.getAccessToken();
		if (accessToken) {
			const errorMsg = t('errors.file_upload');
			await fileUpload(errorMsg, accessToken, uploadUrl, imageTypes, event, (mxUrl) => {
				avatarMxcUrl.value = mxUrl;
				getSubmitButton().enabled = true;
			});
		} else {
			console.error('Access Token is invalid for File upload.');
		}
	}

	async function removeAvatar() {
		avatarMxcUrl.value = '';
		getSubmitButton().enabled = true;
	}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Dialog, {
    title: _ctx.$t('settings.title'),
    buttons: _unref(buttonsSubmitCancel),
    onClose: _cache[4] || (_cache[4] = ($event: any) => (dialogAction($event)))
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t('settings.avatar')), 1),
          _createElementVNode("input", {
            type: "file",
            id: "avatar",
            accept: "image/png, image/jpeg, image/svg",
            class: "hidden",
            ref: "file",
            onChange: _cache[0] || (_cache[0] = ($event: any) => (uploadAvatar($event)))
          }, null, 544),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(Avatar, {
              user: _unref(user),
              overrideAvatarUrl: _unref(avatarUrl),
              class: "w-32 h-32 rounded-full"
            }, null, 8, ["user", "overrideAvatarUrl"]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("label", _hoisted_5, [
                _createVNode(Icon, {
                  size: "lg",
                  type: "edit",
                  class: "group-hover:block cursor-pointer"
                })
              ]),
              _createVNode(Icon, {
                size: "lg",
                type: "bin",
                class: "group-hover:block cursor-pointer",
                onClick: removeAvatar
              })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('settings.displayname')), 1),
          _createVNode(TextInput, {
            class: "md:w-4/6 p-1 border rounded focus:outline-none focus:border-blue-500",
            name: "displayname",
            modelValue: _unref(formState).data.displayName.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(formState).data.displayName.value) = $event)),
            modelModifiers: { trim: true },
            placeholder: _ctx.$t('settings.displayname'),
            onChanged: _cache[2] || (_cache[2] = ($event: any) => (_unref(formState).updateData('displayName', $event)))
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('settings.userId')), 1),
          _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(user).user.userId), 1)
        ])
      ], 32),
      _createVNode(ValidationErrors, {
        errors: _unref(formState).validationErrors.value
      }, null, 8, ["errors"]),
      (_unref(formState).message.value !== '')
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_unref(formState).message), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "buttons"]))
}
}

})