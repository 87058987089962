import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "h-full pl-20 md:px-6 bg-hub-background-2" }
const _hoisted_2 = { class: "flex justify-between relative gap-x-2 h-full w-full" }
const _hoisted_3 = {
  key: 0,
  class: "flex shrink-0 gap-x-1 md:gap-x-4 items-center w-[75%] md:w-[60%] overflow-hidden"
}
const _hoisted_4 = { class: "flex flex-col" }

import { usePubHubs } from '@/core/pubhubsStore';
	import { LOGGER } from '@/foundation/Logger';
	import { SMI } from '@/dev/StatusMessage';
	import { TSearchParameters } from '@/model/search/TSearch';
	import { useHubSettings } from '@/store/hub-settings';
	import { PluginProperties, usePlugins } from '@/store/plugins';
	import { useRooms } from '@/store/rooms';
	import { useUser } from '@/store/user';
	import { computed, onMounted, ref, watch } from 'vue';
	import { useRoute, useRouter } from 'vue-router';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Room',
  props: {
		id: { type: String, required: true },
	},
  setup(__props) {

	const route = useRoute();
	const rooms = useRooms();
	const router = useRouter();
	const plugins = usePlugins();
	const plugin = ref(false as boolean | PluginProperties);
	const hubSettings = useHubSettings();
	const user = useUser();
	const pubhubs = usePubHubs();

	//Passed by the router
	const props = __props;

	const searchParameters = ref<TSearchParameters>({ roomId: props.id, term: '' });
	const scrollToEventId = ref<string>('');

	const room = computed(() => {
		let r = rooms.rooms[props.id];
		if (!r) {
			// I want the side effect that should be avoided according to the lint rule.
			// eslint-disable-next-line
			router.push({ name: 'error-page', query: { errorKey: 'errors.cant_find_room' } });
		}
		return r;
	});

	onMounted(() => {
		update();
		LOGGER.log(SMI.ROOM_TRACE, `Room mounted `);
	});

	watch(route, () => {
		update();
	});

	async function update() {
		hubSettings.hideBar();
		rooms.changeRoom(props.id);

		const userIsMemberOfRoom = await pubhubs.isUserRoomMember(user.user.userId, props.id);
		if (!userIsMemberOfRoom) {
			// if not a member: try to join, otherwise go to the hubpage
			pubhubs.joinRoom(props.id).catch(() => router.push({ name: 'hubpage' }));
		}

		if (!rooms.currentRoom) return;
		searchParameters.value.roomId = rooms.currentRoom.roomId;
		plugin.value = plugins.hasRoomPlugin(rooms.currentRoom);
	}

	async function onScrollToEventId(ev: any) {
		scrollToEventId.value = ev.eventId;
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_PrivateRoomName = _resolveComponent("PrivateRoomName")!
  const _component_RoomName = _resolveComponent("RoomName")!
  const _component_TruncatedText = _resolveComponent("TruncatedText")!
  const _component_H1 = _resolveComponent("H1")!
  const _component_RoomTopic = _resolveComponent("RoomTopic")!
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_RoomTimeline = _resolveComponent("RoomTimeline")!
  const _component_MessageInput = _resolveComponent("MessageInput")!
  const _component_HeaderFooter = _resolveComponent("HeaderFooter")!

  return (_unref(rooms).currentRoomExists)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (plugin.value === false)
          ? (_openBlock(), _createBlock(_component_HeaderFooter, { key: 0 }, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    (_unref(rooms).currentRoom)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createVNode(_component_Icon, {
                            type: _unref(rooms).currentRoom.isSecuredRoom() ? 'shield' : 'speech_bubbles',
                            class: "shrink-0",
                            size: "lg"
                          }, null, 8, ["type"]),
                          _createElementVNode("div", _hoisted_4, [
                            _createVNode(_component_H1, { class: "flex" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_TruncatedText, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('rooms.room')) + " ", 1),
                                    (_unref(rooms).currentRoom.isPrivateRoom())
                                      ? (_openBlock(), _createBlock(_component_PrivateRoomName, {
                                          key: 0,
                                          members: _unref(rooms).currentRoom.getOtherJoinedAndInvitedMembers()
                                        }, null, 8, ["members"]))
                                      : (_openBlock(), _createBlock(_component_RoomName, {
                                          key: 1,
                                          room: _unref(rooms).currentRoom
                                        }, null, 8, ["room"]))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_TruncatedText, { class: "hidden md:inline" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_RoomTopic, {
                                  room: _unref(rooms).currentRoom
                                }, null, 8, ["room"])
                              ]),
                              _: 1
                            })
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_SearchInput, {
                      "search-parameters": searchParameters.value,
                      onScrollToEventId: onScrollToEventId,
                      room: _unref(rooms).currentRoom
                    }, null, 8, ["search-parameters", "room"])
                  ])
                ])
              ]),
              footer: _withCtx(() => [
                (room.value)
                  ? (_openBlock(), _createBlock(_component_MessageInput, {
                      key: 0,
                      room: room.value
                    }, null, 8, ["room"]))
                  : _createCommentVNode("", true)
              ]),
              default: _withCtx(() => [
                (room.value)
                  ? (_openBlock(), _createBlock(_component_RoomTimeline, {
                      key: 0,
                      room: room.value,
                      "scroll-to-event-id": scrollToEventId.value,
                      onScrolledToEventId: _cache[0] || (_cache[0] = ($event: any) => (scrollToEventId.value = ''))
                    }, null, 8, ["room", "scroll-to-event-id"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (plugin.value !== false && plugin.value !== true)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(plugin.value.component), { key: 1 }))
          : _createCommentVNode("", true)
      ], 64))
    : _createCommentVNode("", true)
}
}

})