import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center gap-1" }
const _hoisted_2 = ["innerHTML"]

import { TMessageEvent } from '@/model/events/TMessageEvent';
	import { computed } from 'vue';
	import { useI18n } from 'vue-i18n';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Message',
  props: {
    event: {},
    deleted: { type: Boolean }
  },
  setup(__props: any) {

	const { t } = useI18n();
	const props = __props;

	const message = computed(() => {
		return props.deleted ? t('message.delete.message_deleted') : props.event.content.ph_body;
	});

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.deleted)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          type: "bin",
          size: "sm"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("p", {
      innerHTML: message.value,
      class: _normalizeClass([{ 'dark:text-gray-lighter text-gray': _ctx.deleted }, "text-ellipsis overflow-hidden"])
    }, null, 10, _hoisted_2)
  ]))
}
}

})