import { withAsyncContext as _withAsyncContext, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-hub-background-3 flex px-2 gap-3 items-center rounded-md cursor-pointer" }
const _hoisted_2 = {
  key: 0,
  class: "text-nowrap"
}
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "truncate" }

import { useUserColor } from '@/composables/useUserColor';
	import { computed } from 'vue';
	import Room from '@/model/rooms/Room';
	import { useI18n } from 'vue-i18n';
	import { usePubHubs } from '@/core/pubhubsStore';

	type Props = {
		eventId: string;
		// Whether or not to show the text "In reply to:" inside the snippet.
		showInReplyTo?: boolean;
		room: Room;
	};

	
export default /*@__PURE__*/_defineComponent({
  __name: 'MessageSnippet',
  props: {
    eventId: {},
    showInReplyTo: { type: Boolean, default: false },
    room: {}
  },
  async setup(__props: any) {

let __temp: any, __restore: any

	const { color, textColor } = useUserColor();
	const pubhubs = usePubHubs();
	const { t } = useI18n();

	const props = __props;

	const event = (
  ([__temp,__restore] = _withAsyncContext(() => pubhubs.getEvent(props.room.roomId, props.eventId))),
  __temp = await __temp,
  __restore(),
  __temp
);

	const userColor = computed(() => color(event.sender!) || 0);
	const text = computed(() => {
		return event.content?.body as string;
	});

	const redactedMessage = computed(() => {
		const inRedactedMessageIds = event.event_id && props.room.inRedactedMessageIds(event.event_id);
		const containsRedactedBecause = event.unsigned?.redacted_because != undefined;
		return inRedactedMessageIds || containsRedactedBecause;
	});

	const snippetText = computed(() => {
		return redactedMessage.value ? t('message.delete.original_message_deleted') : text.value;
	});

return (_ctx: any,_cache: any) => {
  const _component_UserDisplayName = _resolveComponent("UserDisplayName")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showInReplyTo)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('message.in_reply_to')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("p", {
      class: _normalizeClass([_unref(textColor)(userColor.value), "truncate"])
    }, [
      _createVNode(_component_UserDisplayName, {
        user: _unref(event).sender,
        room: _ctx.room
      }, null, 8, ["user", "room"])
    ], 2),
    _createElementVNode("p", {
      class: _normalizeClass(["truncate flex items-center gap-1", { 'theme-light:text-gray-middle dark:text-gray-lighter': redactedMessage.value }]),
      title: snippetText.value
    }, [
      (redactedMessage.value)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            type: 'bin',
            size: 'sm'
          }))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_4, _toDisplayString(snippetText.value), 1)
    ], 10, _hoisted_3)
  ]))
}
}

})