import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex gap-1" }

import device from '@/core/device';
	
export default /*@__PURE__*/_defineComponent({
  __name: 'RoomEventActionsPopup',
  setup(__props) {

	const isTouchDevice = device.isTouchDevice();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ block: _unref(isTouchDevice), 'hidden group-hover:block': !_unref(isTouchDevice) })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}
}

})